
import { Action, Getter } from "vuex-class";
import { Component, Ref, Watch, Mixins } from "vue-property-decorator";
import { FormBase, FormInput, FormSubmit, FormSelect, FolderSelect } from "@/components/forms";
import { CanGenerateTimelineEntries } from "@/mixins/can-generate-timeline-entries";
import { HasFormErrors } from "@/mixins/has-form-errors";

import { DocumentAccess, DocumentFolder, DocumentType } from "@/store/modules/document.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        Spinner: () => import("@/components/general/spinner.vue"),
        FolderSelect,
        FormBase,
        FormInput,
        FormSelect,
        FormSubmit,
    },
})
export default class DocumentUploadModal extends Mixins(HasFormErrors, CanGenerateTimelineEntries) {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("temp-media/media") media!: Media[];
    @Getter("temp-media/reset") resetMedia!: () => void;
    @Getter("temp-media/uploading") uploading!: boolean;
    @Getter("temp-media/total") totalUploaded!: number;
    @Getter("temp-media/uploaded") currentUploaded!: number;
    @Action("document/create") createDocument!: DocumentCreate;

    @Ref() form!: FormClass;

    payloads: DocumentCreatePayload[] = [];

    show: boolean = false;

    get foldersTypes() {
        let fileMapping = [
            {
                folder: DocumentFolder.mediationAgreement,
                placeholders: [DocumentType.bemiddelingsOvereenkomst],
            },
            {
                folder: DocumentFolder.legalProvisions,
                placeholders: [
                    DocumentType.eigendomstitel,
                    DocumentType.ovam,
                    DocumentType.stedenbouw,
                    DocumentType.rooilijnplan,
                    DocumentType.kadastraalPlan,
                    DocumentType.kasatraleLegger,
                    DocumentType.kadastraleLeggerAlgemeneDelen,
                    DocumentType.rvv,
                    DocumentType.klim,
                    DocumentType.watertoets,
                    DocumentType.actaMaps,
                    DocumentType.vgiUittreksel,
                    DocumentType.asbestInventaris,
                    DocumentType.risicokaartWaterbeheerder,
                ],
                folders: [
                    {
                        folder: DocumentFolder.syndic,
                        placeholders: [DocumentType.basisakte, DocumentType.jaarverslag1, DocumentType.jaarverslag2, DocumentType.jaarverslag3, DocumentType.verslagAv1, DocumentType.verslagAv2, DocumentType.verslagAv3, DocumentType.artikel57711, DocumentType.balans1, DocumentType.balans2, DocumentType.balans3, DocumentType.epcAlgDelen, DocumentType.blokpolis],
                    },
                ],
            },
            {
                folder: DocumentFolder.inspections,
                placeholders: [DocumentType.epc, DocumentType.elektrischeKeuring, DocumentType.offerteKeuringConform, DocumentType.stookolietankKeuring, DocumentType.zonnepanelenKeuring],
                folders: [
                    {
                        folder: DocumentFolder.vge,
                        placeholders: [],
                        folders: [
                            {
                                folder: DocumentFolder.vgeEpc,
                                placeholders: [DocumentType.vgeEpcDocumentationIsolation, DocumentType.vgeEpcDocumentationTechnics, DocumentType.vgeEpcDocumentationConstruction_plans, DocumentType.vgeEpcDocumentationOther, DocumentType.vgeEpcDocumentationPhotos],
                            },
                            {
                                folder: DocumentFolder.vgeEk,
                                placeholders: [DocumentType.vgeEkDocumentationSchemes, DocumentType.vgeEkDocumentationOther, DocumentType.vgeEkInvoiceRegularisation],
                            },
                            {
                                folder: DocumentFolder.vgeAsbestos,
                                placeholders: [DocumentType.vgeAsbestosAssignmentAgreement, DocumentType.vgeAsbestosDocumentationConstructionPlans, DocumentType.vgeAsbestosDocumentationOther, DocumentType.vgeAsbestosDocumentationPostIntervention],
                            },
                            {
                                folder: DocumentFolder.vgeImmoSigns,
                                placeholders: [DocumentType.vgeImmoSignPhotos, DocumentType.vgeImmoSignDocumentationExtra, DocumentType.vgeImmoSignDesignFiles],
                            },
                        ],
                    },
                    {
                        folder: DocumentFolder.asbestosProject,
                        placeholders: [DocumentType.asbestosProjectAssignmentAgreement, DocumentType.asbestosProjectDocumentationConstructionPlans, DocumentType.asbestosProjectDocumentationOther, DocumentType.asbestosProjectDocumentationPostIntervention],
                    },
                    {
                        folder: DocumentFolder.otherInspections,
                        placeholders: [],
                    },
                ],
            },
            {
                folder: DocumentFolder.photos,
                placeholders: [],
                folders: [
                    { folder: DocumentFolder.advisuersfiche, placeholders: [] },
                    { folder: DocumentFolder.board, placeholders: [] },
                    { folder: DocumentFolder.pictures, placeholders: [] },
                    { folder: DocumentFolder.measurementPlan, placeholders: [] },
                    { folder: DocumentFolder.socialMedia, placeholders: [] },
                ],
            },
            {
                folder: DocumentFolder.salesAgreement,
                placeholders: [],
                folders: [
                    { folder: DocumentFolder.openSalesAgreement, placeholders: [] },
                    { folder: DocumentFolder.approvedSalesAgreement, placeholders: [DocumentType.aankoopbelofte] },
                    { folder: DocumentFolder.salesAgreementSub, placeholders: [] },
                ],
            },
            {
                folder: DocumentFolder.varia,
                placeholders: [],
            },
            {
                folder: DocumentFolder.invoice,
                placeholders: [],
            },
        ];

        const items: any = [];

        fileMapping.forEach((map) => {
            items.push({
                label: this.$t(`views.documents.folders.${map.folder}`),
                icon: "folder",
                type: "folder",
                value: map.folder,
            });

            (map?.placeholders ?? []).forEach((placeholder: any) => {
                items.push({
                    label: this.$t(`views.documents.placeholders.${placeholder}`),
                    icon: "doc",
                    type: "placeholder",
                    indent: 1,
                    folder: map.folder,
                    value: placeholder,
                });
            });

            (map?.folders ?? []).forEach((subMap) => {
                items.push({
                    label: this.$t(`views.documents.folders.${subMap.folder}`),
                    icon: "folder",
                    type: "folder",
                    indent: 1,
                    value: subMap.folder,
                });

                (subMap?.placeholders ?? []).forEach((placeholder: any) => {
                    items.push({
                        label: this.$t(`views.documents.placeholders.${placeholder}`),
                        icon: "doc",
                        type: "placeholder",
                        indent: 2,
                        folder: subMap.folder,
                        value: placeholder,
                    });
                });

                // @ts-ignore
                (subMap?.folders ?? []).forEach((subsubMap) => {
                    items.push({
                        label: this.$t(`views.documents.folders.${subsubMap.folder}`),
                        icon: "folder",
                        type: "folder",
                        indent: 2,
                        value: subsubMap.folder,
                    });

                    (subsubMap?.placeholders ?? []).forEach((placeholder: any) => {
                        items.push({
                            label: this.$t(`views.documents.placeholders.${placeholder}`),
                            icon: "doc",
                            type: "placeholder",
                            indent: 3,
                            folder: subsubMap.folder,
                            value: placeholder,
                        });
                    });
                });
            });
        });

        console.log(items);

        return items;
    }

    async submit(form: FormClass) {
        try {
            let promises: any = [];
            this.payloads.forEach((p) => {
                promises.push(this.createDocument(p));
            });

            await Promise.all(promises);

            this.handleClose();

            form.reset();

            this.$toast.open({ message: this.$t("views.documents.create_success") as string, type: "success", position: "bottom-right" });

            this.setTimelineCheckUpdate(true);
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        }
    }

    handleClose() {
        this.payloads = [];

        this.clearErrors();

        this.show = false;
    }

    @Watch("media", { deep: true })
    handleMediaChanged() {
        if (this.media) {
            this.show = true;
            this.payloads = [];

            (this.media ?? []).forEach((m) => {
                let p = {
                    name: m.type ? (this.$t(`views.documents.placeholders.${m.type}`) as string) : m.name,
                    activity_id: this.activity.id,
                    type: m.type ?? null,
                    meta: {
                        folder: m.folder ?? null,
                        access: [DocumentAccess.internal],
                    },
                    media: [m],
                };
                this.payloads.push(p);
            });
        }
    }
}
